import axios from "axios";

export const getCookieList = async () => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/Cookie/GetList`);
}

export const createOrUpdateCookie = async (data) => {
    return await axios.post(`${window.Configuration['API_URL']}/api/v1/Cookie/CreateOrEdit`, data);
}

export const deleteCookie = async (id) => {
    return await axios.post(`${window.Configuration['API_URL']}/api/v1/Cookie/Delete?id=${id}`)
}

export const getCookie = async (id) => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/Cookie/GetById?id=${id}`)
}