import { Button, PageHeader, Popconfirm, Space, Table, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDomainGroups } from '../../services/MasterService';
import { getDomainList, deleteDomain } from '../../services/DomainService';
import TableTextFilter from '../../components/TableTextFilter';

const DomainListPage = (params) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [domainGroups, setDomainGroups] = useState([])
    const [loading, setLoading] = useState(true)
    const columnConfig = [
        {
            title: t('domain'),
            dataIndex: 'domainUrl',
            key: 'domainUrl',
            sorter: (a, b) => a.domainUrl.localeCompare(b.domainUrl),
            filterDropdown: TableTextFilter,
            onFilter: (value, record) => record.domainUrl.toLowerCase().includes(value.toLowerCase())
        },
        {
            title: t('group'),
            dataIndex: 'groupDomainName',
            key: 'groupDomainName',
            sorter: (a, b) => a.groupDomainName.localeCompare(b.groupDomainName),
            filters: domainGroups.map((group) => ({
                text: group.value,
                value: group.value
            })),
            onFilter: (value, record) => record.groupDomainName.indexOf(value) === 0
        },
        {
            title: t('action'),
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={{
                        pathname: `/domains/edit/${record.id}`,
                        state: {
                            domain: record
                        }
                    }}>{t('edit')}</Link>
                    <Popconfirm
                        title={t('removeDomainConfirm')}
                        okText={t('yes')}
                        cancelText={t('no')}
                        onConfirm={(e) => { doDelete(record.id) }}>
                        <Link to="">{t('remove')}</Link>
                    </Popconfirm>
                </Space>
            ),
        }
    ]

    useEffect(() => {
        fetchDomains();
        fetchDomainGroups();
    }, [])

    const fetchDomainGroups = async () => {
        try {
            const resp = await getDomainGroups();
            
            if (resp?.data?.success) {
                setDomainGroups(resp.data.payload)
            } else {
                notification.error({message: (resp?.data?.errors ?? []).join(' \n')})
            }
        } catch (e) { }
    }

    const fetchDomains = async () => {
        setLoading(true);
        try {
            const resp = await getDomainList();
            if (resp?.data?.success) {
                (resp?.data?.payload ?? []).forEach(item => {
                    item.key = item.id
                })
                setData(resp?.data?.payload ?? [])
            } else {
                notification.error({message: (resp?.data?.errors ?? []).join(' \n')})
            }
        } catch (e) { }
        setLoading(false)
    }

    const doDelete = async (id) => {
        try {
            const resp = await deleteDomain(id);
            if (resp?.data?.success) {
                notification.success({ message: t('dataDeletedNotification') })
                fetchDomains();
            } else {
                notification.error({message: (resp?.data?.errors ?? []).join(' \n')})
            }
        } catch (e) { }
    }

    return (
        <>
            <Layout>
                <PageHeader className="button-container-header"
                    title=""
                    extra={[
                        <Button key="1" type="primary">
                            <Link to="/domains/new">
                                {t('new')}
                            </Link>
                        </Button>
                    ]} />
                <Content>
                    <Table
                        columns={columnConfig}
                        dataSource={data}
                        scroll={{ y: 'calc(100vh - 239px)' }}
                        locale={{
                            triggerDesc: t('tableTriggerDesc'),
                            triggerAsc: t('tableTriggerAsc'),
                            cancelSort: t('tableCancelSort'),
                            emptyText: t('noData')
                        }}
                        pagination={{
                            locale: { items_per_page: t('paginationPageSize') }
                        }}
                        loading={loading} />
                </Content>
            </Layout>
        </>
    )
}

export default DomainListPage;