import { Button, Col, notification, PageHeader, Popconfirm, Row, Space, Table, Tag } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCookieCategories, getCookieTypes } from '../../services/MasterService';
import { deleteCookie, getCookieList } from '../../services/CookieService';
import TableTextFilter from '../../components/TableTextFilter';
import { getDomainList } from '../../services/DomainService';

const CookiesListPage = (params) => {
    const { t } = useTranslation()
    const [data, setData] = useState([]);
    const [cookieCategories, setCookieCategories] = useState([])
    const [cookieTypes, setCookieTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [domains, setDomains] = useState([])

    const columnConfig = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            filterDropdown: TableTextFilter,
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase())
        },
        {
            title: t('category'),
            dataIndex: 'cookieCategoryName',
            key: 'cookieCategoryName',
            sorter: (a, b) => a.cookieCategoryName.localeCompare(b.cookieCategoryName),
            filters: cookieCategories.map((cookieCategory) => ({
                text: cookieCategory.value,
                value: cookieCategory.value
            })),
            onFilter: (value, record) => record.cookieCategoryName.indexOf(value) === 0
        },
        {
            title: t('domain'),
            dataIndex: 'cookieDomains',
            key: 'cookieDomains',
            width: '35%',
            sorter: (a, b) => a.cookieDomains.map(domain => domains.find(d => d.id === domain.domainId)?.domainUrl).join(', ').localeCompare(b.cookieDomains.map(domain => domains.find(d => d.id === domain.domainId)?.domainUrl).join(', ')),
            filterDropdown: TableTextFilter,
            onFilter: (value, record) => record.cookieDomains.map(domain => domains.find(d => d.id === domain.domainId)?.domainUrl).join(', ').toLowerCase().includes(value.toLowerCase()),
            render: (value, record) => {
                return(
                    <div>
                        {
                            record.cookieDomains.map(domain => (
                                <Tag key={domain.id}>{domains.find(d => d.id === domain.domainId)?.domainUrl}</Tag>
                            ))
                        }
                    </div>
                )
            }
        },
        {
            title: t('purpose'),
            dataIndex: 'cookiePurposes',
            key: 'cookiePurposes',
            width: '25%',
            filterDropdown: TableTextFilter,
            onFilter: (value, record) => record.cookiePurposes.filter(purpose => purpose.description).map(purpose => purpose.description).join(", ").toLowerCase().includes(value.toLowerCase()),
            render: (text, record) => record.cookiePurposes.filter(purpose => purpose.description).map(purpose => purpose.description).join(", ")
        },
        {
            title: t('type'),
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => cookieTypes.find(c => c.key === a.type).value.localeCompare(cookieTypes.find(c => c.key === b.type).value),
            filters: cookieTypes.map((cookieType) => ({
                text: cookieType.value,
                value: cookieType.key
            })),
            onFilter: (value, record) => record.type === value,
            render: (text, record) => {
                const cookieType = cookieTypes.find(cookieType => cookieType.key === record.type);
                return cookieType ? cookieType.value : ""
            }
        },
        {
            title: t('expiresIn'),
            dataIndex: 'expiredDays',
            key: 'expiredDays'
        },
        {
            title: t('action'),
            key: 'action',
            render: (text, record) => (
                <Content>
                    <Row>
                        <Col>
                            <Link to={`/cookies/edit/${record.id}`}>{t('edit')}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Popconfirm
                                title={t('removeCookieConfirm')}
                                okText={t('yes')}
                                cancelText={t('no')}
                                onConfirm={(e) => { doDelete(record.id) }}>
                                <Link to="">{t('remove')}</Link>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Content>
            ),
        }
    ]

    useEffect(() => {
        fetchDomains();
        fetchCookieCategories()
        fetchCookieTypes()
    }, [])

    useEffect(() => {
        if (cookieTypes.length > 0 && domains.length > 0)
            fetchCookies()
    }, [cookieTypes, domains])

    const fetchCookies = async () => {
        setLoading(true)
        try {
            const resp = await getCookieList()
            resp.data.payload.forEach(item => {
                item.key = item.id
            })
            setData(resp.data.payload)
        } catch (e) { }
        setLoading(false)
    }

    const fetchCookieCategories = async () => {
        try {
            const resp = await getCookieCategories();
            if (resp?.data?.success) {
                setCookieCategories(resp?.data?.payload ?? [])
            }
        } catch (e) { }
    }

    const fetchCookieTypes = async () => {
        try {
            const resp = await getCookieTypes()
            if (resp?.data?.success) {
                setCookieTypes(resp?.data?.payload ?? [])
            }
        } catch (e) { }
    }

    const doDelete = async (id) => {
        try {
            const resp = await deleteCookie(id);
            if (resp?.data?.success) {
                notification.success({ message: t('dataDeletedNotification') })
                fetchCookies();
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
    }

    const fetchDomains = async () => {
        setLoading(true);
        try {
            const resp = await getDomainList();
            if (resp?.data?.success) {
                setDomains(resp?.data?.payload ?? [])
            } else {
                notification.error({message: (resp?.data?.errors ?? []).join(' \n')})
            }
        } catch (e) { }
        setLoading(false)
    }

    return (
        <>
            <Layout>
                <PageHeader className="button-container-header"
                    title=""
                    extra={[
                        <Button key="1" type="primary">
                            <Link to="/cookies/new">
                                {t('new')}
                            </Link>
                        </Button>
                    ]} />
                <Content>
                    <Table
                        columns={columnConfig}
                        dataSource={data}
                        scroll={{ y: 'calc(100vh - 239px)' }}
                        locale={{
                            triggerDesc: t('tableTriggerDesc'),
                            triggerAsc: t('tableTriggerAsc'),
                            cancelSort: t('tableCancelSort'),
                            emptyText: t('noData')
                        }}
                        pagination={{
                            locale: { items_per_page: t('paginationPageSize') }
                        }}
                        loading={loading} />
                </Content>
            </Layout>
        </>
    )
}

export default CookiesListPage;