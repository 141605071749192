export const languages = [
    "en",
    "fr",
    "es",
    "ar",
    "cn",
    "nl",
    "id",
    "it",
    "ja",
    "ko",
    "ro",
    "ru",
    "tr",
    "ua",
    "pt"
]