import React from 'react'
import { Layout, Menu } from 'antd';
import Routes from '../routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const { Content, Sider } = Layout;

const MainContainer = (params) => {
    const { t } = useTranslation();
    return (
        <Layout style={{ height: '100vh' }}>
            <Content style={{ padding: '0 50px' }}>
                <Layout className="site-layout-background" style={{ padding: '24px 0', height: '100%' }}>
                    <Sider className="site-layout-background" width={200} style={{ width: 200 }}>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            style={{ height: '100%' }}
                        >
                            <Menu.Item key="1">
                                <Link to="/domains">
                                    <span>{t('domains')}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/cookies">
                                    <span>{t('cookies')}</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Content style={{ padding: '0 24px', minHeight: 280, overflow: 'scroll' }}>
                        <Routes />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default MainContainer;