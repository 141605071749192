import axios from "axios";

export const getDomainList = async () => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/Domain/GetList`);
}

export const createOrUpdateDomain = async (data) => {
    return await axios.post(`${window.Configuration['API_URL']}/api/v1/Domain/CreateOrEdit`, data);
}

export const deleteDomain = async (id) => {
    return await axios.post(`${window.Configuration['API_URL']}/api/v1/Domain/Delete?id=${id}`)
}

export const getDomain = async (id) => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/Domain/GetById?id=${id}`)
}