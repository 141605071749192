import 'antd/dist/antd.css';
import './App.css';
import { MemoryRouter as Router } from 'react-router-dom';
import MainContainer from './pages/MainContainer';
import axios from 'axios'
import "./i18n";
import { notification } from 'antd';

axios.interceptors.request.use(async (config) => {
    if (window.Configuration["API_KEY"] !== null) config.headers['x-api-key'] = window.Configuration["API_KEY"];
    config.timeout = 30000;
    return config;
})

axios.interceptors.response.use(
    response => {
        console.log(response.config.url, response.data)
        return response
    },
    error => {
        console.log(JSON.stringify(error))
        switch (error?.response?.status) {
            case 401:
                notification.error({ message: "Unauthorized (401)", description: "Invalid API Key" })
                return Promise.reject({ ...error })
            case 500:
                notification.error({ message: "Internal Server Error (500)", description: "Oops something is wrong with the server" })
                return Promise.reject({ ...error })
            default: break;
        }

        notification.error({ description: error.message })
        return Promise.reject({ ...error })
    }
)

function App() {
    return (
        <Router>
            <MainContainer />
        </Router>
    );
}

export default App;
