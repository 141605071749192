import axios from "axios";

export const getDomainGroups = async () => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/General/GetDomainGroup`);
}

export const getCookieCategories = async () => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/General/GetCookieCategory`);
}

export const getCookieTypes = async () => {
    return await axios.get(`${window.Configuration['API_URL']}/api/v1/General/GetCookieType`);
}