import { Route, Switch } from "react-router-dom"
import CookiesFormPage from "./pages/cookies/CookiesFormPage"
import CookiesListPage from "./pages/cookies/CookiesListPage"
import DomainFormPage from "./pages/domain/DomainFormPage"
import DomainListPage from "./pages/domain/DomainListPage"

const Routes = () => {
    return (
        <Switch>
            <Route path="/cookies" exact>
                <CookiesListPage />
            </Route>
            <Route path="/cookies/new" exact>
                <CookiesFormPage />
            </Route>
            <Route path="/cookies/edit/:id" exact>
                <CookiesFormPage />
            </Route>
            <Route path="/domains" exact>
                <DomainListPage />
            </Route>
            <Route path="/domains/new" exact>
                <DomainFormPage />
            </Route>
            <Route path="/domains/edit/:id" exact>
                <DomainFormPage />
            </Route>
            <Route path="/">
                <DomainListPage />
            </Route>
        </Switch>
    )
}

export default Routes;