import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Form, Button, Col, Input, PageHeader, Row, Select, Tabs, notification, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { getCookieCategories, getCookieTypes } from '../../services/MasterService';
import { getDomainList } from '../../services/DomainService';
import Modal from 'antd/lib/modal/Modal';
import { languages } from '../../assets/constants/constants'
import { createOrUpdateCookie, deleteCookie, getCookie } from '../../services/CookieService';

const CookiesFormPage = (params) => {
    const history = useHistory()
    const { t } = useTranslation()
    const { id } = useParams()

    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [category, setCategory] = useState('')
    const [expired, setExpired] = useState('')
    const [isHttpOnly, setIsHttpOnly] = useState(false)
    const [isSecure, setIsSecure] = useState(false)
    const [domains, setDomains] = useState([])
    const [firstFoundUrl, setFirstFoundUrl] = useState('')
    const [provider, setProvider] = useState('')
    const [domainPath, setDomainPath] = useState('')
    const [example, setExample] = useState('')
    const [purpose, setPurpose] = useState({})
    const [domainOptions, setDomainOptions] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [categoryOptions, setCategoryOptions] = useState([])
    const [typeOptions, setTypeOptions] = useState([])
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [activeTab, setActiveTab] = useState(languages[0])
    const [formErrors, setFormErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const validate = () => {
        const errors = {}
        let hasError = false;
        if (!name) {
            errors.name = "Required";
            hasError = true;
        }
        if (!category) {
            errors.category = "Required"
            hasError = true
        }
        if (!expired) {
            errors.expired = "Required"
            hasError = true
        }
        if (expired && expired <= 0) {
            errors.expired = "Expiration must be greater than 0"
            hasError = true;
        }
        if (!domains || domains.length === 0) {
            errors.domains = "Required"
            hasError = true
        }
        if (!type) {
            errors.type = "Required"
            hasError = true
        }

        if (Object.keys(purpose).length === 0) {
            errors.purpose = "Required at least 1 language"
            hasError = true
        }
        setFormErrors(errors)

        return hasError
    }

    const save = async () => {
        if (validate()) return;
        setIsSaving(true)
        const params = {
            id: id,
            name: name,
            type: type,
            cookieCategory: category,
            isSupportHttp: isHttpOnly,
            isSupportHttps: isSecure,
            firstFoundUrl: firstFoundUrl,
            provider: provider,
            domainPath: domainPath,
            exampleValue: example,
            expiredDays: expired,
            cookieDomains: domains.map(domain => ({ domainId: domain })),
            cookiePurposes: languages.map(language => ({ language: language, description: purpose[language] }))
        }

        try {
            const resp = await createOrUpdateCookie(params)
            if (resp?.data?.success) {
                notification.success({ message: "Data Saved" })
                history.goBack()
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
        setIsSaving(false)
    }

    const remove = async () => {
        try {
            const resp = await deleteCookie(id)
            setIsDeleteModalVisible(false)
            if (resp?.data?.success) {
                notification.success({ message: t('dataDeletedNotification') })
                history.goBack()
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
    }

    const cancel = () => {
        history.goBack()
    }

    useEffect(() => {
        const initPurpose = {}
        languages.forEach((language) => {
            initPurpose[language] = ''
        })
        setPurpose(initPurpose)

        fetchDomains()
        fetchCookieCategories()
        fetchCookieTypes()
        if (id) {
            fetchCookie();
        }

    }, [])

    const onPurposeChanged = (e) => {
        const newPurpose = { ...purpose }
        newPurpose[activeTab] = e.target.value
        setPurpose(newPurpose)
    }

    const onTabChanged = (key) => {
        setActiveTab(key)
    }

    const fetchDomains = async () => {
        try {
            const resp = await getDomainList();
            if (resp?.data?.success) {
                (resp?.data?.payload ?? []).forEach(item => {
                    item.key = item.id
                })
                setDomainOptions(resp?.data?.payload ?? [])
            }
        } catch (e) { }
    }

    const fetchCookieCategories = async () => {
        try {
            const resp = await getCookieCategories();
            if (resp?.data?.success) {
                setCategoryOptions(resp?.data?.payload ?? [])
            }
        } catch (e) { }
    }

    const fetchCookieTypes = async () => {
        try {
            const resp = await getCookieTypes()
            if (resp?.data?.success) {
                setTypeOptions(resp?.data?.payload ?? [])
            }
        } catch (e) { }
    }

    const fetchCookie = async () => {
        setLoading(true)
        try {
            const resp = await getCookie(id)
            if (resp?.data?.success) {
                const cookie = resp.data.payload;
                setName(cookie.name)
                setType(cookie.type)
                setCategory(cookie.cookieCategory)
                setExpired(cookie.expiredDays)
                setIsHttpOnly(cookie.isSupportHttp)
                setIsSecure(cookie.isSupportHttps)
                setDomains((cookie.cookieDomains ?? []).map(domain => domain.domainId))
                setFirstFoundUrl(cookie.firstFoundUrl)
                setProvider(cookie.provider)
                setDomainPath(cookie.domainPath)
                setExample(cookie.exampleValue)
                const oldPurpose = {}
                for (let i = 0; i < (cookie.cookiePurposes ?? []).length; i++) {
                    const cPurpose = cookie.cookiePurposes[i];
                    oldPurpose[cPurpose.language] = cPurpose.description;
                }
                setPurpose(oldPurpose)
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
        setLoading(false)
    }

    const onExpirationChanged = (val) => {
        let newVal = val.replace(/\D/g, '');
        newVal = newVal.replace(/^0+/, '');
        setExpired(newVal)
    }

    return (
        <Spin spinning={loading}>
            <Layout>
                <PageHeader className="button-container-header"
                    onBack={() => history.goBack()}
                    title={id ? t('edit') : t('new')} />
                <Content style={{ backgroundColor: '#fff', padding: 24 }}>
                    <Form layout="horizontal"
                        labelCol={{ span: 6 }}
                        labelAlign="left"
                        size="large">
                        <Form.Item label={<span>{t('name')} <font color="red">*</font></span>}
                            validateStatus={formErrors['name'] ? "error" : ""}
                            help={formErrors['name']}>
                            <Input value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={<span>{t('type')} <font color="red">*</font></span>}
                            validateStatus={formErrors['type'] ? "error" : ""}
                            help={formErrors['type']}>
                            <Select value={type} onChange={(val) => setType(val)}>
                                {typeOptions.map((option) => <Select.Option value={option.key} key={option.key}>{option.value}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={<span>{t('cookieCategory')} <font color="red">*</font></span>}
                            validateStatus={formErrors['category'] ? "error" : ""}
                            help={formErrors['category']}>
                            <Select value={category} onChange={(val) => setCategory(val)}>
                                {categoryOptions.map((option) => <Select.Option value={option.key} key={option.key}>{option.value}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={<span>{t("expiresIn")} <font color="red">*</font></span>}
                            validateStatus={formErrors['expired'] ? "error" : ""}
                            help={formErrors['expired']}>
                            <Input addonAfter={t("days")} value={expired} onChange={(e) => onExpirationChanged(e.target.value)} pattern="[0-9]*" />
                        </Form.Item>
                        <Form.Item>
                            <Row>
                                <Col span={4} offset={6}>
                                    <Checkbox checked={isHttpOnly} onChange={(e) => setIsHttpOnly(e.target.checked)}>{t('httpOnly')}</Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4} offset={6}>
                                    <Checkbox checked={isSecure} onChange={(e) => setIsSecure(e.target.checked)}>{t('secure')}</Checkbox>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label={<span>{t('domains')} <font color="red">*</font></span>}
                            validateStatus={formErrors['domains'] ? "error" : ""}
                            help={formErrors['domains']}>
                            <Select value={domains} onChange={(val) => setDomains(val)} mode="multiple"
                                showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {domainOptions.map((item) => <Select.Option value={item.id} key={item.id}>{item.domainUrl}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={t("firstFoundUrl")}>
                            <Input value={firstFoundUrl} onChange={(e) => setFirstFoundUrl(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={t("provider")}>
                            <Input value={provider} onChange={(e) => setProvider(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={t("domainPath")}>
                            <Input value={domainPath} onChange={(e) => setDomainPath(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={t("exampleValue")}>
                            <Input value={example} onChange={(e) => setExample(e.target.value)} />
                        </Form.Item>
                        <Tabs activeKey={activeTab} onChange={onTabChanged} type="card">
                            {languages.map((language) => (
                                <Tabs.TabPane tab={t(`lang_${language}`)} key={language}>
                                    <Form.Item label={<span>{t("cookiePurpose")} <font color="red">*</font></span>}
                                        validateStatus={formErrors['purpose'] ? "error" : ""}
                                        help={formErrors['purpose']}>
                                        <Input.TextArea rows={4} value={purpose[activeTab]} onChange={onPurposeChanged} />
                                    </Form.Item>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>

                        <Row gutter={[16, 0]} style={{ marginTop: 0 }}>
                            {id &&
                                <Col span="4">
                                    <Button type="primary" danger style={{ width: '100%' }} onClick={() => setIsDeleteModalVisible(true)}>{t('remove')}</Button>
                                </Col>
                            }
                            <Col span="4" offset={id ? 12 : 16}>
                                <Button style={{ width: '100%' }} onClick={cancel}>{t('cancel')}</Button>
                            </Col>
                            <Col span="4">
                                <Button type="primary" style={{ width: '100%' }} onClick={save} loading={(isSaving || loading)}>{isSaving ? t('submitting') : t('submit')}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Content>
            </Layout>
            <Modal title={t('areYouSure')}
                visible={isDeleteModalVisible}
                onOk={remove}
                onCancel={() => setIsDeleteModalVisible(false)}
                okType="danger"
                okButtonProps={{ type: "primary" }}
                okText={t('remove')}
                cancelText={t('cancel')}>
                <p>{t('formDeleteCookieMessage')}</p>
            </Modal>
        </Spin>
    )
}

export default CookiesFormPage