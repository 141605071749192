import { Button, Input, Space } from 'antd'
import React from 'react'

const TableTextFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    return (
        <div style={{ padding: 8 }}>
            <Input
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button type="link" onClick={() => clearFilters()} size="small" style={{ marginRight: 90, padding: 0 }}>
                    Reset
                </Button>
                <Button
                    size="small"
                    type="primary"
                    onClick={() => confirm()}
                >
                    OK
                </Button>
            </Space>
        </div>
    )
}

export default TableTextFilter