import { Button, Input, PageHeader, Form, Select, Row, Col, notification, Spin } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDomainGroups } from '../../services/MasterService';
import { getDomain, createOrUpdateDomain, deleteDomain } from '../../services/DomainService';
import Modal from 'antd/lib/modal/Modal';

const DomainFormPage = (params) => {
    const history = useHistory()
    const { t } = useTranslation()
    const { id } = useParams()
    const [name, setName] = useState('')
    const [group, setGroup] = useState('')
    const [expired, setExpired] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [groupOptions, setGroupOptions] = useState([])
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({})

    const validateForm = (params) => {
        const errors = {};
        let hasError = false;
        if (!params.domainUrl) {
            errors.name = "Required"
            hasError = true;
        }
        if (!params.groupDomain) {
            errors.group = "Required"
            hasError = true;
        }
        if (!params.expiredMonth) {
            errors.expired = "Required"
            hasError = true;
        }
        if (params.expiredMonth && params.expiredMonth <= 0) {
            errors.expired = "Expiration must be greater than 0"
            hasError = true;
        }
        setFormErrors(errors)
        return hasError;
    }
    const save = async () => {
        const params = {
            id: id,
            domainUrl: name,
            groupDomain: group,
            expiredMonth: expired
        }
        if (validateForm(params)) {
            return;
        }
        setIsSaving(true)
        try {
            const resp = await createOrUpdateDomain(params)
            if (resp?.data?.success) {
                notification.success({ message: "Data Saved" })
                history.goBack()
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
        setIsSaving(false)
    }

    const remove = async () => {
        try {
            const resp = await deleteDomain(id)
            setIsDeleteModalVisible(false)
            if (resp?.data?.success) {
                notification.success({ message: t('dataDeletedNotification') })
                history.goBack()
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
    }

    const cancel = () => {
        history.goBack()
    }

    useEffect(() => {
        fetchDomainGroups();
        if (id) {
            fetchDomain()
        }
    }, [])

    const fetchDomain = async () => {
        setLoading(true)
        try {
            const resp = await getDomain(id)
            if (resp?.data?.success) {
                const domain = resp.data.payload;
                setName(domain.domainUrl)
                setGroup(domain.groupDomain)
                setExpired(domain.expiredMonth)
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
        setLoading(false)
    }

    const fetchDomainGroups = async () => {
        try {
            const resp = await getDomainGroups()
            if (resp?.data?.success) {
                setGroupOptions(resp.data.payload)
            } else {
                notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
    }

    const onExpirationChanged = (val) => {
        let newVal = val.replace(/\D/g, '');
        newVal = newVal.replace(/^0+/, '');
        setExpired(newVal)
    }

    return (
        <Spin spinning={loading}>
            <Layout>
                <PageHeader className="button-container-header"
                    onBack={() => history.goBack()}
                    title={id ? t('edit') : t('new')} />
                <Content style={{ backgroundColor: '#fff', padding: 24 }}>
                    <Form layout="horizontal"
                        labelCol={{ span: 6 }}
                        labelAlign="left"
                        size="large">
                        <Form.Item label={<span>{t('domain')} <font color="red">*</font></span>}
                            validateStatus={formErrors['name'] ? "error" : ""}
                            help={formErrors['name']}>
                            <Input value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={<span>{t('groupDomain')} <font color="red">*</font></span>}
                            validateStatus={formErrors['group'] ? "error" : ""}
                            help={formErrors['group']}>
                            <Select value={group} onChange={(val) => setGroup(val)}>
                                {groupOptions.map(domainGroup => <Select.Option key={domainGroup.key} value={domainGroup.key}>{domainGroup.value}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={<span>{t("userConsentExpiration")} <font color="red">*</font></span>}
                            validateStatus={formErrors['expired'] ? "error" : ""}
                            help={formErrors['expired']}>
                            <Input addonAfter={t("months")} value={expired} onChange={(e) => onExpirationChanged(e.target.value)} pattern="[0-9]*" />
                        </Form.Item>
                        <Row gutter={[16, 0]} style={{ marginTop: 0 }}>
                            {id &&
                                <Col span="4">
                                    <Button type="primary" danger style={{ width: '100%' }} onClick={() => setIsDeleteModalVisible(true)}>{t('remove')}</Button>
                                </Col>
                            }
                            <Col span="4" offset={id ? 12 : 16}>
                                <Button style={{ width: '100%' }} onClick={cancel}>{t('cancel')}</Button>
                            </Col>
                            <Col span="4">
                                <Button type="primary" style={{ width: '100%' }} onClick={save} loading={(isSaving || loading)}>{isSaving ? t('submitting') : t('submit')}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Content>
            </Layout>
            <Modal title={t('areYouSure')}
                visible={isDeleteModalVisible}
                onOk={remove}
                onCancel={() => setIsDeleteModalVisible(false)}
                okType="danger"
                okButtonProps={{ type: "primary" }}
                okText={t('remove')}
                cancelText={t('cancel')}>
                <p>{t('formDeleteDomainMessage')}</p>
            </Modal>
        </Spin>
    )
}

export default DomainFormPage;